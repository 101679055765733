<template>
    <div class="navigation">
        <div class="grid grid-cols-10 gap-1 mt-2">
            <div class="cursor-pointer flex font-semibold rounded-md h-8 md:h-14 w-8 md:w-14 items-center border-2 mb-1 mx-auto" 
                @click="getPertanyaan(data.urutan)"
                v-for="(data, index) in navigation" :key="index"
                :class="
                    (data.id_kuis_soal_opsi == null ? 
                        'bg-red-100 hover:text-red-800 text-red-600' : 
                        'bg-green-100 hover:text-green-800 text-green-600' )
                    + ' ' +
                    (data.urutan == urutanAktif ? 'border-yellow-300' : 'border-transparent') 
                "
                >
                <div class="text-center w-full">{{ data.urutan }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TestMateriNavigation',
    props: {
        navigation : {
            type : Array,
        },
        urutanAktif : {
            type : Number
        },
        getPertanyaan : {
            type : Function
        }
    }
}
</script>
