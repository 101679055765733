<template>
    <div class="relative">
        
        <div class="header w-full h-24 bg-blue-600 rounded-3xl rounded-t-none px-3 pt-5">
        </div>

        <div class="hero px-4 -mt-11">
            <div class="bg-white w-full rounded-2xl p-3 h-40 shadow-lg flex flex-col justify-center">
                <h2 class="text-center text-xl font-semibold text-gray-800 uppercase " v-if="materi_user_skor != null">{{ materi_user_skor.materi_konten.judul_konten }}</h2>
                
                <div class="flex space-x-2 mx-auto ">
                    <div class="flex flex-col items-center">
                        <h4 class="text-black text-4xl text-center font-semibold">{{ countDown.hour }}</h4>
                        <span class="text-xs text-gray-800">Jam</span>
                    </div>
                    <h4 class="text-gray-400 text-xl text-center font-semibold">:</h4>
                    <div class="flex flex-col items-center">
                        <h4 class="text-black text-4xl text-center font-semibold">{{ countDown.minute }}</h4>
                        <span class="text-xs text-gray-800">Menit</span>
                    </div>
                    <h4 class="text-gray-400 text-xl text-center font-semibold">:</h4>
                        <div class="flex flex-col items-center">
                        <h4 class="text-black text-4xl text-center font-semibold">{{ countDown.second }}</h4>
                        <span class="text-xs text-gray-800">Detik</span>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="isLoadingPertanyaan == false && pertanyaan != null">
            <div class="my-4 px-4 py-2">
                <div>
                    <div class="bg-blue-200 px-2 py-1 w-auto inline-block rounded mb-3 mr-2">
                        <div class="font-semibold text-blue-800 text-sm">{{ pertanyaan.sub_kategori }}</div>
                    </div>
                    <div class="bg-gray-200 px-2 py-1 w-auto inline-block rounded mb-3" v-if="pertanyaan.nama_bab != null ">
                        <div class="font-semibold text-gray-800 text-sm">{{ pertanyaan.nama_bab }}</div>
                    </div>
                </div>

                <div class="mb-2 border-b-2 flex space-x-2 items-center pb-2">
                    <div class="font-bold">Nomor Soal</div>
                    <div class="w-5">
                        <div class="rounded-full bg-blue-400 w-6 h-6 font-semibold text-white items-center flex align-middle mr-2">
                            <div class="mx-auto">{{ page }}</div>
                        </div>
                    </div>
                </div>

                <div class="flex mb-2">
                    <p class="mb-4" v-html="pertanyaan.pertanyaan"></p>
                </div>
                
                <div class="option">
                    <div :key="index" v-for="(data, index) in pertanyaan.opsi" class="option-item 
                        bg-white rounded-lg 
                        px-3 py-2 mb-2 cursor-pointer 
                        border-2
                        transition-all flex items-center"
                        :class="(id_opsi_jawaban == data.id_kuis_soal_opsi) ? 'border-blue-600' : 'border-gray-200' "
                        @click="kirimJawaban(data.id_kuis_soal_opsi)"
                    >
                        <div class="w-9 flex-none h-9 mr-2 bg-gray-100 rounded-md flex font-semibold items-center text-center">
                            <div class="mx-auto font-semibold">{{ option_list[index] }}</div>
                        </div>
                        <div v-html="data.isi" class="flex-grow"></div>
                        <div class="ml-1">
                            <span class="w-4 h-4 inline-block mr-1 rounded-full border "
                                :class="(id_opsi_jawaban == data.id_kuis_soal_opsi) ? 'bg-blue-600 border-blue-600' : 'border-gray-200' "
                            ></span>
                        </div>
                    </div> 
                </div>
                <div class="flex flex-row mt-3 space-x-2">
                    <button @click="kirimJawaban(null)" class="w-1/2 px-4 py-2 text-center rounded bg-gray-100 text-gray-500 focus:outline-none">Hapus Jawaban</button>
                    <button
                        @click="kirimJawabanDanLanjutkan()"
                        :disabled="isLoadingButtonSimpan"
                        class="transition-colors relative w-1/2 justify-center px-4 py-2 rounded bg-green-100 hover:text-green-800 text-green-600 focus:outline-none
                        ">
                        <span v-if="isLoadingButtonSimpan" class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        Simpan & Lanjutkan
                    </button>
                </div>

            </div>
        </div>

        <div class="animate-pulse px-4 mb-3" v-if="isLoadingPertanyaan">
            <div class="w-full h-5 bg-gray-100 rounded-lg mb-1"/>
            <div class="w-full h-5 bg-gray-100 rounded-lg mb-1"/>
            <div class="w-1/2 h-5 bg-gray-100 rounded-lg mb-1"/>
            <div v-for="i in 5" :key="i" class="mt-2 rounded-lg bg-white border-2 border-gray-100 px-3 py-2">
                <div class="w-full h-5 bg-gray-100 rounded-lg"/>
            </div>
        </div>

        <!-- navigation -->
        <div class="px-4">
            <test-materi-navigation :navigation="navigation" :urutanAktif="page" :getPertanyaan="getPertanyaan" />
        </div>

        <div class="px-4 mt-3">
            <button
                @click="isModalConfirmFinish = true"
                class="transition-colors w-full px-4 py-2 rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none
                ">
                Selesai
            </button>
        </div>

        <div class="h-16"/>
        <!-- apply button -->
        <div class="py-2 px-1 bg-white w-full fixed bottom-0 right-0 left-0 border-t-2 border-gray-100">
            <div class="max-w-4xl grid grid-cols-2 mx-auto space-x-1">
                <button
                    @click="getPertanyaan(page - 1)"
                    class="relative justify-center py-2 px-4 border border-transparent text-sm 
                    font-medium rounded-md text-gray-600 bg-gray-100 
                    focus:outline-none"
                    :class="page == 1 ? 'opacity-0' : ''" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                    </svg>
                </button>
                <button
                    @click="getPertanyaan(page + 1)"
                    class="justify-center py-2 px-4 border border-transparent text-sm 
                    font-medium rounded-md text-gray-600 bg-gray-100 
                    focus:outline-none"
                    :class="page == total_pertanyaan ? 'opacity-0' : ''">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                </button>
            </div>
        </div>

        <modal :isOpen="isModalConfirmFinish">
            <div class="md:flex items-center">
                <div class="bg-blue-200 rounded-full flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                    </svg>
                </div>
                <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                    <p class="font-bold">Selesai Menjawab</p>
                    <p class="text-sm text-gray-700 mt-1">
                        Apakah anda yakin akan melakukan ini?
                    </p>
                </div>
            </div>
            <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                <button @click="finishQuiz()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-yellow-200 text-yellow-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                    <svg v-if="isLoadingFinishQuiz" class="animate-spin ml-1 mr-3 h-5 w-5 text-yellow-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Yakin
                </button>
                <button @click="isModalConfirmFinish = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Batal</button>
            </div>
        </modal>


        <modal :isOpen="isOpenModalFinish" v-if="kuisResult != null">
            <div v-if="kuisResult.skor.nilai_akhir < kuisResult.skor.materi_konten.passing_grade">
                <div class="w-40 mx-auto">
                    <img src="@/assets/images/fail.svg" class="w-full"/>
                </div>
                <div class="w-2/3 mx-auto mt-6">
                    <h2 class="text-center text-xl font-semibold tracking-tight mt-4 text-gray-500">
                        <span class="text-4xl text-red-700">{{ kuisResult.skor.nilai_akhir }}</span>/{{ materi_user_skor.materi_konten.passing_grade }}
                    </h2>
                    <p class="text-center">Maaf, Anda belum lolos passing grade.</p>
                </div>
            </div>

            <div v-if="kuisResult.skor.nilai_akhir >= kuisResult.skor.materi_konten.passing_grade">
                <div class="w-40 mx-auto">
                    <img src="@/assets/images/success.svg" class="w-full"/>
                </div>
                <div class="w-2/3 mx-auto mt-6">
                    <h2 class="text-center text-xl font-semibold tracking-tight mt-4 text-gray-500">
                        <span class="text-4xl text-green-700">{{ kuisResult.skor.nilai_akhir }}</span>/{{ materi_user_skor.materi_konten.passing_grade }}
                    </h2>
                    <p class="text-center">Selamat, Anda dinyatakan lulus dalam kuis ini.</p>
                </div>
            </div>

            <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                <button @click="ulangKuis()" 
                    class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-yellow-200 text-yellow-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                    <svg v-if="isLoadingKerjakanKuis" class="animate-spin ml-1 mr-3 h-5 w-5 text-yellow-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Ulangi
                </button>
                <button @click="gotoKonten(kuisResult.next)" v-if="kuisResult.is_enable_next == 1 && kuisResult.next != null" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Lanjutkan Materi</button>
            </div>

        </modal>

   </div>
</template>

<script>
    import Modal from '../../components/Modal.vue';
    import { errorHandler, showErrorNotif, showSuccessNotif } from '../../helpers/Tools';
    import moment from 'moment'
    import TestMateriNavigation from '../../components/TestMateriNavigation.vue';

    export default {
        components: { Modal, TestMateriNavigation },
        name: 'JawabKuisMateri',
        data(){
            return {
                isLoadingMain        : true,
                isLoadingPertanyaan  : true,
                isLoadingFinishQuiz  : false,
                isModalConfirmFinish : false,
                page                 : 1,
                materi_user_skor     : null,
                navigation           : [],
                pertanyaan           : null,
                id_opsi_jawaban      : null,
                countDown            : {
                    hour  : '00',
                    minute: '00',
                    second: '00',
                },
                kuisResult           : null,
                isOpenModalFinish    : true,
                isLoadingKerjakanKuis: false,
                total_pertanyaan     : 0,
                isCounter            : true,
                option_list          : ['A', 'B', 'C', 'D', 'E'],
                isLeave              : false
            }
        },
        mounted() {
            this.detailKuisByToken();
            this.getPertanyaan(this.page);
		},
        beforeRouteLeave (to, from , next) {
            if(this.isCounter) {
                const answer = window.confirm('Kuis akan otomatis diselesaikan apabila anda meninggalkan halaman ini. Apakah anda yakin?')
                if (answer) {
                    this.isLeave = true
                    this.finishQuiz()
                    next()
                } else {
                    next(false)
                }
            } else {
                next();
            }
        },
		methods : {

            showTimeCountDown(nowTime, startTime, timeLimit) {
                var eventTime = moment(startTime).add(timeLimit, 'minutes')
                var currentTime = moment(nowTime)
                var diffTime = eventTime.diff(currentTime, 'milliseconds')
                var interval = 1000

                setInterval(() => {
                    diffTime = diffTime - interval
                    if (diffTime <= 0 && this.isCounter) {
                        this.timeOutRedirect()
                    }
                    if(this.isCounter) {
                        const tempTime = moment.duration(diffTime)
                        this.countDown = {
                            'hour'  : tempTime.hours().pad(2),
                            'minute': tempTime.minutes().pad(2),
                            'second': tempTime.seconds().pad(2)
                        }
                    }
                }, 1000);
            },

            async timeOutRedirect() {
                this.finishQuiz();
            },

            async detailKuisByToken() {
                this.isLoadingMain = true;
                try {

                    let res = await this.$store.dispatch("getDetailSkorMateribyToken", {
                        token : this.$route.params.token
                    });
                    let result = res.data;
                    this.isLoadingMain = false;

                    if(result.status == 'success') {
                        this.materi_user_skor = result.data;
                        this.showTimeCountDown(result.data.server_time, result.data.start_time, result.data.materi_konten.waktu_pengerjaan);
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingMain = false;
                    errorHandler(this, error);
                }
            },

            async getPertanyaan(page) {
                page = Number(page);
                this.page                = page;
                this.isLoadingPertanyaan = true;
                try {

                    let res = await this.$store.dispatch("getPertanyaanKuisMateri", {
                        token: this.$route.params.token,
                        page : page
                    });
                    let result = res.data;
                    this.isLoadingPertanyaan = false;

                    if(result.status == 'success') {
                        let data_result = result.data;
                        this.pertanyaan = data_result.pertanyaan.data[0];
                        this.navigation = data_result.navigasi;
                        this.id_opsi_jawaban = this.pertanyaan.id_kuis_soal_opsi;
                        this.total_pertanyaan = data_result.pertanyaan.total;
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingPertanyaan = false;
                    errorHandler(this, error);
                }
            },

            async kirimJawabanDanLanjutkan() {
                this.isLoadingButtonSimpan = true;
                
                try {
                    let latest_opsi = this.id_opsi_jawaban;
                    let id_kuis_soal_opsi = this.id_opsi_jawaban;

                    let res = await this.$store.dispatch("postJawabanKuisMateri", {
                        token            : this.$route.params.token,
                        id_kuis_soal     : this.pertanyaan.id_kuis_soal,
                        id_kuis_soal_opsi: id_kuis_soal_opsi
                    });
                    let result = res.data;
                    this.isLoadingButtonSimpan = false;

                    if(result.status != 'success') {
                        showErrorNotif(this, result.message);
                        this.id_opsi_jawaban = latest_opsi;
                    } else {
                        if(this.total_pertanyaan != this.page) {
                            var page = this.page;
                            this.getPertanyaan(page + 1)
                        }
                        this.navigation = result.data.navigasi;
                    }
                } catch (error) {
                    this.isLoadingButtonSimpan = false;
                    errorHandler(this, error);
                }
                
            },


            async kirimJawaban(id_kuis_soal_opsi) {
                this.id_opsi_jawaban = id_kuis_soal_opsi;
            },

            async finishQuiz() {
                this.isLoadingFinishQuiz = true;
                this.isCounter           = false;
                try {
                    let res = await this.$store.dispatch("selesaiKuisMateri", {
                        token : this.$route.params.token
                    });
                    let result                    = res.data;
                    let result_data               = result.data;
                        this.isLoadingFinishQuiz  = false;
                        this.isModalConfirmFinish = false;
                    if(result.status == 'success') {
                        showSuccessNotif(this, result.message); 
                        this.kuisResult        = result_data;
                        this.isOpenModalFinish = true
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingFinishQuiz = false;
                    errorHandler(this, error);
                }
            },


            async ulangKuis() {
                this.isLoadingKerjakanKuis = true;
                try {

                    let res = await this.$store.dispatch("startKuisMateri", {
                        id_materi       : this.materi_user_skor.materi_konten.id_materi,
                        id_materi_konten: this.materi_user_skor.id_materi_konten,
                    });
                    let result = res.data;
                    this.isLoadingKerjakanKuis = false;

                    if(result.status == 'success') {
                        showSuccessNotif(this, result.message);

                        let resultData = result.data;
                        this.$router.replace({ name: 'JawabKuisMateri', params: { 
                            singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                            singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                            id_materi             : this.materi_user_skor.id_materi,
                            id_materi_konten      : this.materi_user_skor.id_materi_konten,
                            token                 : resultData.token
                        }});

                        this.page = 1;
                        this.detailKuisByToken();
                        this.getPertanyaan(this.page);
                        this.isOpenModalFinish = false

                    } else {
                        showErrorNotif(this, result.message);
                    }

                } catch (error) {
                    this.isLoadingKerjakanKuis = false;
                    errorHandler(this, error);
                }
            },

            async gotoKonten (materi_konten_selanjutnya) {
                this.isLoadingMain = true;

                try {
                    let res = await this.$store.dispatch("getDetailKontenMateri", {
                        id_materi              : materi_konten_selanjutnya.id_materi,
                        id_materi_konten       : materi_konten_selanjutnya.id_materi_konten,
                        
                    });
                    let result = res.data
                    this.isLoadingMain = false;
                    
                    if(result.status == 'success') {
                        this.$router.replace({ name: 'ViewMateriKonten', params: { 
                            singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                            singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                            id_materi             : materi_konten_selanjutnya.id_materi,
                            id_materi_konten      : materi_konten_selanjutnya.id_materi_konten
                        }});
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            }

        }
    }
</script>
